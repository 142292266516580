<!--
 * @Author: SongYijie
 * @Date: 2020-05-06 17:54:45
 * @LastEditors: SongYijie
--> 
<template>
  <div class="main has-footer bg-gray taskCenter">
    <!-- 轮播图 -->
    <top-banner/>
    <task-list state="0" pageSize="4"></task-list>
  </div>
</template>

<script>
  import topBanner from "@components/task/index-top-banner";
  import taskList from "@components/task/task-list"
  export default {
    components: {
      topBanner,
      taskList
    },
    data() {
      return {}
    },
    mounted() {
    }
  };
</script>

<style lang='less' scoped>
.taskCenter {
  position: position;
  padding-bottom: 13.3333vw;
}
</style>
